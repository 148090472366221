import { createSlice } from "@reduxjs/toolkit";

export const breakPoints = {
    xl: 1200,
    lg: 1001,
    md: 768,
    sm: 576,
    ssm: 507,
    xs: 471,
    xxs: 328,
    xxxs: 0,
};

export interface IScreenBreakpointsState {
    curBreakpoint: number;
}

const screenBreakpointState: IScreenBreakpointsState = {
    curBreakpoint: -1,
};

const screenBreakpoints = createSlice({
    name: "screenBreakpoints",
    initialState: screenBreakpointState,
    reducers: {
        setScreenBreakpoint(state, action) {
            if (action.payload >= breakPoints.xl) state.curBreakpoint = breakPoints.xl;
            else if (action.payload >= breakPoints.lg) state.curBreakpoint = breakPoints.lg;
            else if (action.payload >= breakPoints.md) state.curBreakpoint = breakPoints.md;
            else if (action.payload >= breakPoints.sm) state.curBreakpoint = breakPoints.sm;
            else if (action.payload >= breakPoints.ssm) state.curBreakpoint = breakPoints.ssm;
            else if (action.payload >= breakPoints.xs) state.curBreakpoint = breakPoints.xs;
            else if (action.payload >= breakPoints.xxs) state.curBreakpoint = breakPoints.xxs;
            else if (action.payload < breakPoints.xxs) state.curBreakpoint = breakPoints.xxxs;
        },
    },
});

export const { setScreenBreakpoint } = screenBreakpoints.actions;
export default screenBreakpoints.reducer;
