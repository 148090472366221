import { applyMiddleware, createStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { IProductDataState, productDataState } from "./slices/productDataSlice";
import { IScreenBreakpointsState } from "./slices/screenBreakpointsSlice";
import { IUserDataState, userDataState } from "./slices/userDataSlice";

var logger = createLogger({
    collapsed: true,
});

export interface IDefaultState {
    screenBreakpoints: IScreenBreakpointsState;
    productData: IProductDataState;
    userData: IUserDataState;
}

var tempStore;

if (process.env.NODE_ENV === "development") tempStore = createStore(rootReducer, applyMiddleware(thunk, logger));
else tempStore = createStore(rootReducer);

export const store = tempStore;

export const defaultState: IDefaultState = {
    screenBreakpoints: { curBreakpoint: -1 },
    productData: productDataState,
    userData: userDataState,
};
