import * as React from "react";
import CSSTransition from "react-transition-group/CSSTransition";

interface ICustomDropdownProps {
    height?: string;
    className?: string;
    itemList?: IDropdownItem[] | any[];
    headerText?: string;
    setHeaderToSelected?: boolean;
    defaultSelected?: string;
    horizontalCollapse?: boolean;
    parent?: CustomDropdown;
    itemPixelHeight: number;
    useMaxItemCountAsHeight?: boolean;
    useFirstLevelItemCountAsHeight?: boolean;
}

export interface IDropdownItem {
    label: string;
    callback: any;
    itemIndex: number;
    sublist: IDropdownItem[] | null;
}

class CustomDropdownState {
    isOpen: boolean = false;
    curSelectedItem: string = "";
    isChildOpen: boolean = false;
    maxHeight: number = 0;

    constructor(isOpen: boolean = false, curSelectedItem: string = "") {
        this.isOpen = isOpen;
        this.curSelectedItem = curSelectedItem;
    }
}

export class CustomDropdown extends React.PureComponent<ICustomDropdownProps, CustomDropdownState> {
    private timer: NodeJS.Timer | undefined;
    private selectEvent: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = new CustomDropdownState();
    }

    calculateMaxItemCount(itemList: IDropdownItem[] | null | undefined) {
        if (itemList) {
            let accumulatedValue: number = 1;
            let sublistCount: number = 0;
            itemList.forEach((item: IDropdownItem) => {
                accumulatedValue++;
                if (!this.props.useFirstLevelItemCountAsHeight) sublistCount = Math.max(this.calculateMaxItemCount(item.sublist), sublistCount);
            });

            return accumulatedValue + sublistCount;
        }

        return 0;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps !== this.props) {
            if (prevProps.defaultSelected !== this.props.defaultSelected) {
                this.setState({
                    ...this.state,
                    curSelectedItem: this.props.defaultSelected ? this.props.defaultSelected : this.state.curSelectedItem,
                });
            }
        }
    }

    isSelectEvent(event: any): boolean {
        return event instanceof Event;
    }

    toggleBlur(event: any) {
        if (this.state.isOpen) {
            setTimeout(() => this.toggleCollapse(event), 100);
        } else {
            setTimeout(() => {
                if (this.props.parent && !this.state.isChildOpen) this.props.parent.forceClose();
            }, 100);
        }
    }

    handleItemSelect(event: any, value: IDropdownItem) {
        var curItem = value ? value : this.props.itemList ? this.props.itemList[parseInt(event.target.dataset.index)] : { label: "heading", callback: null };
        if (curItem) if (curItem.callback) curItem.callback(event);

        this.setState(new CustomDropdownState(this.state.isOpen, curItem.label));
    }

    forceClose() {
        if (!this.selectEvent || !this.state.isChildOpen) this.setState({ ...this.state, isOpen: false, isChildOpen: false });
    }

    toggleCollapse(event: any, itemLabel: string = "", forceChildClose: boolean = false) {
        let newState: CustomDropdownState = {
            ...this.state,
            isOpen: !this.state.isOpen,
            curSelectedItem: itemLabel.length > 0 ? itemLabel : this.state.curSelectedItem,
        };

        if (!forceChildClose && this.state.isChildOpen) newState.isOpen = true;

        if (!newState.isOpen) newState.isChildOpen = false;

        this.setState(newState);

        if (!newState.isOpen && this.props.parent && this.props.parent.state.isChildOpen) {
            this.props.parent.forceClose();
        }
    }

    toggleOnEnter(listWrapper: HTMLElement) {
        var maxHeight = this.props.useMaxItemCountAsHeight ? this.calculateMaxItemCount(this.props.itemList) : this.props.itemList ? this.props.itemList.length : 0;

        listWrapper.style.maxHeight = maxHeight > 0 ? `${maxHeight * this.props.itemPixelHeight}px` : "100%";
    }

    toggleOnExit(listWrapper: HTMLElement) {
        listWrapper.style.maxHeight = "0";
    }

    renderItems() {
        var renderArr: any[] = [];

        if (this.props.itemList) {
            this.props.itemList.forEach((value, index) => {
                renderArr[index] = (
                    <div
                        key={"item-" + index}
                        id={`${index}`}
                        className="customdd-list-item"
                        onClick={
                            !value.sublist
                                ? (event: any) => {
                                      this.handleItemSelect(event, value);
                                  }
                                : (event: any) => {
                                      if (this.timer) clearTimeout(this.timer);

                                      this.selectEvent = true;
                                      this.timer = setTimeout(() => (this.selectEvent = false), 100);

                                      this.setState({
                                          ...this.state,
                                          isChildOpen: true,
                                      });
                                  }
                        }
                        data-isopen={this.state.isOpen}
                        data-index={index}
                    >
                        {value.sublist ? (
                            <CustomDropdown className={this.props.className ? this.props.className + "-submenu" : "customdd-wrapper-submenu"} itemList={value.sublist} setHeaderToSelected={false} headerText={value.label} horizontalCollapse={true} parent={this} itemPixelHeight={this.props.itemPixelHeight} useMaxItemCountAsHeight={this.props.useMaxItemCountAsHeight} />
                        ) : (
                            <span style={{ paddingLeft: "2px" }}>{value.label}</span>
                        )}
                    </div>
                );
            });
        }

        return <React.Fragment>{renderArr}</React.Fragment>;
    }

    render() {
        return (
            <div className={this.props.className ? this.props.className : "customdd-wrapper"}>
                <button
                    className="customdd-header d-inline-block"
                    onClick={(event: any) => {
                        this.toggleCollapse(event, this.state.curSelectedItem, true);
                    }}
                    onBlur={(e) => {
                        this.toggleBlur(e);
                    }}
                >
                    <div className="customdd-header-title d-flex flex-row">
                        <span className="mr-auto">{this.props.headerText ? this.props.headerText : this.state.curSelectedItem.length > 0 ? this.state.curSelectedItem : this.props.itemList ? (this.props.itemList.length > 0 ? this.props.itemList[0].label : "") : ""}</span>
                        <div className="d-flex flex-column flex-grow-1 align-items-end justify-content-center">
                            <span className={this.state.isOpen ? "caret-up" : "caret-down"}></span>
                        </div>
                    </div>
                </button>
                <CSSTransition in={this.state.isOpen} timeout={100} classNames="dropdown" appear unmountOnExit onEntering={this.toggleOnEnter.bind(this)} onExiting={this.toggleOnExit.bind(this)}>
                    <div className="customdd-list d-flex flex-column" data-horizontalsubmenu={this.props.horizontalCollapse}>
                        {this.renderItems()}
                    </div>
                </CSSTransition>
            </div>
        );
    }
}
