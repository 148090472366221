import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CSSTransition from "react-transition-group/CSSTransition";
import { breakPoints } from "../../redux/slices/screenBreakpointsSlice";
import { IDefaultState } from "../../redux/store";
import { ProductService } from "../../services/productService";

interface ICartProps {
    isOpen?: boolean;
    maxHeight?: number;
    accessButton?: boolean;
    cartItems: ICartItem[];
    curScreenBreakpoint: number;
    history?: any;
}

export interface ICartItem {
    UniqueId: string;
    Name: string;
    SellingPrice: number;
    SaleAmount: number;
    quantity: number;
    timeStamp: number;
}

export function currencyStyling(value: number) {
    const formatter = new Intl.NumberFormat("en-ZA", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
    });

    return formatter.format(value);
}

class CartState {
    items: ICartItem[] = [];

    constructor(items: ICartItem[] = []) {
        this.items = items;
    }
}

class Cart extends React.PureComponent<ICartProps, CartState> {
    private productService: ProductService = new ProductService();

    constructor(props: any) {
        super(props);
        this.state = new CartState();
    }

    componentDidUpdate(prevProps: ICartProps, prevState: CartState) {}

    addToCart(item: ICartItem) {
        this.setState({ ...this.state, items: this.state.items.concat([item]) });
    }

    removeFromCart(itemIndex: number) {
        this.setState({
            ...this.state,
            items: this.state.items.filter((item: ICartItem, index: number) => {
                if (index === itemIndex) return false;
                return true;
            }),
        });
    }

    toggleOnEnter(cartContainer: HTMLElement) {
        cartContainer.style.maxHeight = this.props.maxHeight && this.props.maxHeight > 0 ? `${this.props.maxHeight}px` : `100%`;
    }

    toggleOnExit(cartContainer: HTMLElement) {
        cartContainer.style.maxHeight = `0px`;
    }

    renderItems() {
        var rowElements: JSX.Element[] = [];

        this.props.cartItems.forEach((item: ICartItem, index: number) => {
            let price: number = item.SaleAmount ? item.SaleAmount : item.SellingPrice;

            rowElements.push(
                <Row key={`r${index}`}>
                    <Col xs={this.props.curScreenBreakpoint > breakPoints.xs ? 1 : undefined} className="col-quantity" key="c0">
                        <button className="item-quantity-up" onClick={(e) => this.productService.setCartQuantity(item.timeStamp, item.quantity + 1)} onMouseDown={this.buttonDownQuantity} onMouseUp={this.buttonUpQuantity} onMouseLeave={this.buttonUpQuantity} onTouchStart={this.buttonDownQuantity} onTouchEnd={this.buttonUpQuantity}>
                            <img src="/img/plus.png" alt="+" />
                        </button>
                        <span>{item.quantity}</span>
                        <button
                            className="item-quantity-down"
                            onClick={(e) => {
                                if (item.quantity > 1) this.productService.setCartQuantity(item.timeStamp, item.quantity - 1);
                            }}
                            onMouseDown={this.buttonDownQuantity}
                            onMouseUp={this.buttonUpQuantity}
                            onMouseLeave={this.buttonUpQuantity}
                            onTouchStart={this.buttonDownQuantity}
                            onTouchEnd={this.buttonUpQuantity}
                        >
                            <img src="/img/minus.png" alt="-" />
                        </button>
                    </Col>
                    {this.props.curScreenBreakpoint > breakPoints.xxs ? (
                        <React.Fragment>
                            <Col xs={3} className="col-productimage" key="c1">
                                <img src={process.env.REACT_APP_IMAGEPATH + "/thumbnail/" + item.UniqueId} alt={`${item.UniqueId} thumbnail`} />
                            </Col>
                            <Col xs={7} className="col-namepricetotal" key="c2">
                                <Link to={`/Products/${item.UniqueId}`} className="nav-link">
                                    {item.Name}&nbsp;&#128279;
                                </Link>
                                <Row className="col-pricetotal">
                                    <Col xs={"auto"} className="col-pricetext">
                                        <span>Price:</span>
                                        <span>Subtotal:</span>
                                    </Col>
                                    <Col xs={"auto"} className="col-price">
                                        <span>{`${currencyStyling(price)}`}</span>
                                        <span>{`${currencyStyling(item.quantity * price)}`}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </React.Fragment>
                    ) : (
                        <Col xs={10} className="col-imgpricetotal" key="c2">
                            <img src={process.env.REACT_APP_IMAGEPATH + "/thumbnail/" + item.UniqueId} alt={`${item.UniqueId} thumbnail`} />

                            <Link to={`/Products/${item.UniqueId}`} style={{ textAlign: "center" }} className="nav-link">
                                {item.Name}&nbsp;&#128279;
                            </Link>
                            <Col className="col-pricetotal">
                                <Row>
                                    <Col />
                                    <Col xs={"auto"} className="price-text">
                                        <span>Price:</span>
                                        <span>Subtotal:</span>
                                    </Col>
                                    <Col xs={"auto"} className="price">
                                        <span>{`${currencyStyling(price)}`}</span>
                                        <span>{`${currencyStyling(item.quantity * price)}`}</span>
                                    </Col>
                                    <Col />
                                </Row>
                            </Col>
                        </Col>
                    )}
                    <Col className="item-remove-col" key="c3">
                        <button className="item-remove" onClick={(e) => this.productService.removeCartItem(item.timeStamp)} onMouseDown={this.buttonDown} onMouseUp={this.buttonUp} onMouseLeave={this.buttonUp}>
                            x
                        </button>
                    </Col>
                    {index < this.props.cartItems.length - 1 ? <hr className="cart-sep" /> : null}
                </Row>
            );
        });

        return <React.Fragment>{rowElements.length < 1 ? <span style={{ paddingLeft: 5 }}>Cart is empty.</span> : rowElements}</React.Fragment>;
    }

    buttonDown(event: any) {
        var element = event.currentTarget;
        element.style.backgroundColor = "rgb(157, 170, 238)";
    }

    buttonUp(event: any) {
        var element = event.currentTarget;
        element.style.backgroundColor = "var(--primary-dark)";
    }

    buttonDownQuantity(event: any) {
        var element = event.currentTarget;
        element.style.backgroundColor = "rgb(157, 170, 238)";
    }

    buttonUpQuantity(event: any) {
        var element = event.currentTarget;
        element.style.backgroundColor = "rgb(230, 230, 230)";
    }

    render() {
        return (
            <CSSTransition in={this.props.isOpen} timeout={400} classNames="cart" appear unmountOnExit onEntering={this.toggleOnEnter.bind(this)} onExiting={this.toggleOnExit.bind(this)}>
                <Container className="cart-container">
                    <Col className="cart">
                        <Row className="cart-header">
                            <Col>
                                <h1>
                                    <span>My Cart</span>
                                    <button
                                        className="cart-maximize"
                                        onClick={(e) => {
                                            window.location.href = "/cart";
                                        }}
                                        onMouseDown={this.buttonDown}
                                        onMouseUp={this.buttonUp}
                                        onMouseLeave={this.buttonUp}
                                        onTouchStart={this.buttonDown}
                                        onTouchEnd={this.buttonUp}
                                    >
                                        View Cart
                                    </button>
                                </h1>
                            </Col>
                        </Row>
                        <Col className="cart-items">{this.renderItems()}</Col>
                        <Row className="cart-footer m-0">
                            <Col>
                                <Row className="item-footer m-0">
                                    Cart total incl VAT: &nbsp;
                                    {(() => {
                                        let total: number = 0;
                                        this.props.cartItems.forEach((item: ICartItem) => (total += item.quantity * item.SellingPrice));
                                        return `${currencyStyling(total)}`;
                                    })()}
                                </Row>
                                <Row className="cart-buttons">
                                    {this.props.cartItems.length > 0 ? (
                                        <React.Fragment>
                                            <button className="cart-clear mr-auto" onClick={(e) => this.productService.clearCart()} onMouseDown={this.buttonDown} onMouseUp={this.buttonUp} onMouseLeave={this.buttonUp} onTouchStart={this.buttonDown} onTouchEnd={this.buttonUp}>
                                                Clear Cart
                                            </button>
                                            <button
                                                className="cart-checkout"
                                                onClick={(event) => {
                                                    window.location.href = "/checkout";
                                                }}
                                                onMouseDown={this.buttonDown}
                                                onMouseUp={this.buttonUp}
                                                onMouseLeave={this.buttonUp}
                                                onTouchStart={this.buttonDown}
                                                onTouchEnd={this.buttonUp}
                                            >
                                                Checkout
                                            </button>
                                        </React.Fragment>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </CSSTransition>
        );
    }
}

export default connect(function mapStateToProps(state: IDefaultState, props) {
    return {
        cartItems: state.productData.cartItems,
        curScreenBreakpoint: state.screenBreakpoints.curBreakpoint,
    };
})(Cart);
