import { lazy } from "react";

const Home = lazy(() => import("./pages/home").then(({ Home }) => ({ default: Home })));

const Components = lazy(() =>
    import("./pages/components").then(({ Components }) => ({
        default: Components,
    }))
);

const Notebooks = lazy(() =>
    import("./pages/notebooks").then(({ Notebooks }) => ({
        default: Notebooks,
    }))
);

const Products = lazy(() =>
    import("./pages/products").then(({ Products }) => ({
        default: Products,
    }))
);

const BuildCustomPc = lazy(() =>
    import("./pages/buildcustompc").then(({ BuildCustomPc }) => ({
        default: BuildCustomPc,
    }))
);

const Sales = lazy(() =>
    import("./pages/sales").then(({ Sales }) => ({
        default: Sales,
    }))
);

const Search = lazy(() =>
    import("./pages/search").then(({ Search }) => ({
        default: Search,
    }))
);

const CartPage = lazy(() =>
    import("./pages/cartPage").then(({ CartPage }) => ({
        default: CartPage,
    }))
);

const Checkout = lazy(() =>
    import("./pages/checkout").then(({ Checkout }) => ({
        default: Checkout,
    }))
);

const Login = lazy(() =>
    import("./pages/login").then(({ Login }) => ({
        default: Login,
    }))
);

const Register = lazy(() =>
    import("./pages/register").then(({ Register }) => ({
        default: Register,
    }))
);

const ChooseComponent = lazy(() =>
    import("./pages/choosecomponent").then(({ ChooseComponent }) => ({
        default: ChooseComponent,
    }))
);

const FAQ = lazy(() =>
    import("./pages/faq").then(({ FAQ }) => ({
        default: FAQ,
    }))
);

const TermsAndConditions = lazy(() =>
    import("./pages/termsandconditions").then(({ TermsAndConditions }) => ({
        default: TermsAndConditions,
    }))
);

const ContactUs = lazy(() => import("./pages/contactus").then(({ ContactUs }) => ({ default: ContactUs })));

const PasswordReset = lazy(() =>
    import("./pages/passwordreset").then(({ PasswordReset }) => ({
        default: PasswordReset,
    }))
);

export type Route = {
    path: string;
    Component: React.ExoticComponent<any>;
    routes?: Route[];
};

const routes: Route[] = [
    {
        path: "/",
        Component: Home,
    },
    {
        path: "/home",
        Component: Home,
    },
    {
        path: "/components",
        Component: Components,
    },
    {
        path: "/components/:component",
        Component: Components,
    },
    {
        path: "/notebooks/:make",
        Component: Notebooks,
    },
    {
        path: "/search",
        Component: Search,
    },
    {
        path: "/contactus",
        Component: ContactUs,
    },
    {
        path: "/buildacustompc",
        Component: BuildCustomPc,
    },
    {
        path: "/sale",
        Component: Sales,
    },
    {
        path: "/products/",
        Component: Products,
    },
    {
        path: "/cart",
        Component: CartPage,
    },
    {
        path: "/checkout",
        Component: Checkout,
    },
    {
        path: "/login",
        Component: Login,
    },
    {
        path: "/register",
        Component: Register,
    },
    {
        path: "/choosecomponent",
        Component: ChooseComponent,
    },
    {
        path: "/faq",
        Component: FAQ,
    },
    {
        path: "/termsandconditions",
        Component: TermsAndConditions,
    },
    {
        path: "/passwordreset",
        Component: PasswordReset,
    },
];

export default routes;
