import * as React from "react";
import { connect } from "react-redux";
import { breakPoints } from "../../redux/slices/screenBreakpointsSlice";
import { IDefaultState } from "../../redux/store";
import { ProductService } from "../../services/productService";

interface IPageNavProps {
    className?: string;
    callback?: any;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    curScreenBreakpoint: number;
}

class PageNavState {
    pageRange: number;
    curPage: number;

    constructor(pageRange: number = 5, curPage: number = 1) {
        this.pageRange = pageRange;
        this.curPage = curPage;
    }
}

class PageNav extends React.PureComponent<IPageNavProps, PageNavState> {
    private static lastDataRequest: number = -1;
    private productService: ProductService = new ProductService();
    private start: number = 0;
    private end: number = 0;

    constructor(props: any) {
        super(props);

        this.state = new PageNavState();

        this.handleSelectPage = this.handleSelectPage.bind(this);
    }

    //async handleSelectPage(pageNumber: number);
    //async handleSelectPage(event: React.SyntheticEvent<HTMLDivElement>);

    async handleSelectPage(event: React.SyntheticEvent<HTMLDivElement> | number) {
        var nextPage = 1;

        if (typeof event === "number") {
            if (event < 1) {
                nextPage = 1;
            } else if (event > this.props.totalPages) {
                nextPage = this.props.totalPages;
            } else {
                nextPage = event;
            }
        } else {
            nextPage = parseInt(event.currentTarget.dataset.value ? event.currentTarget.dataset.value : "");
        }

        this.setState(new PageNavState(this.state.pageRange, nextPage));

        var curTime = new Date().getTime();

        PageNav.lastDataRequest = curTime;
        this.productService.setProductDataLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 100));

        if (curTime === PageNav.lastDataRequest) {
            this.productService.setCurrentPage(nextPage);
            this.productService.getProductData();
        }
    }

    static getDerivedStateFromProps(props: any, state: any) {
        if (props.curScreenBreakpoint <= breakPoints.md) return new PageNavState(5, state.curPage);

        return null;
    }

    renderPageNumbers() {
        var pageHtml: any[] = [];
        var rangeGroup: number = this.state.curPage % this.state.pageRange;

        //Start conditions
        if (this.state.curPage < this.props.totalPages) {
            if (this.state.curPage > this.state.pageRange) {
                if (rangeGroup === 0) {
                    this.start = this.state.curPage - (this.state.pageRange - 1);
                } else if (rangeGroup === 1) {
                    this.start = this.state.curPage;
                } else {
                    this.start = this.state.curPage - (rangeGroup - 1);
                }
            } else if (this.state.curPage <= this.state.pageRange) {
                this.start = 1;
            }
        } else {
            if (rangeGroup === 0) {
                this.start = this.props.totalPages - (this.state.pageRange - 1);
            } else if (rangeGroup === 1) {
                this.start = this.props.totalPages;
            } else this.start = this.props.totalPages - (rangeGroup - 1);
        }

        //End conditions
        if (this.start + (this.state.pageRange - 1) >= this.props.totalPages) {
            this.end = this.props.totalPages;
        } else {
            this.end = this.start + (this.state.pageRange - 1);
        }

        var arrIndex: number = 0;

        for (var i = this.start; i <= this.end; i++) {
            pageHtml[arrIndex] = (
                <div className="page-num mr-1" onClick={this.handleSelectPage} data-selected={i === this.state.curPage ? 1 : 0} data-value={i} key={i}>
                    {i}
                </div>
            );

            if (i !== this.end)
                pageHtml[arrIndex + 1] = (
                    <div className="nav-sep mr-1" key={"pagenum-sep-" + i}>
                        |
                    </div>
                );

            arrIndex += 2;
        }

        return pageHtml;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.totalPages !== this.props.totalPages) {
            this.setState(new PageNavState(this.state.pageRange, 1));
            this.productService.setCurrentPage(1);
        }

        if (this.props.currentPage !== this.state.curPage && prevProps.currentPage !== this.props.currentPage) this.setState({ ...this.state, curPage: this.props.currentPage });
    }

    render() {
        return (
            <div className={(this.props.className ? this.props.className : "") + " d-flex flex-row"}>
                <span
                    className="page-prev mr-1"
                    onClick={() => {
                        this.handleSelectPage(this.state.curPage - 1);
                    }}
                >
                    &lt;
                </span>
                <div className="nav-sep mr-1">|</div>
                {this.state.curPage <= this.state.pageRange ? null : (
                    <div className="page-num mr-1" onClick={() => this.handleSelectPage(1)} data-pagenum={1}>
                        1...
                    </div>
                )}
                {this.renderPageNumbers()}
                {this.end === this.props.totalPages ? null : (
                    <div className="page-num mr-1" onClick={() => this.handleSelectPage(this.props.totalPages)} data-pagenum={this.props.totalPages}>
                        ...{this.props.totalPages}
                    </div>
                )}
                <div className="nav-sep mr-1">|</div>
                <span
                    className="page-next"
                    onClick={() => {
                        this.handleSelectPage(this.state.curPage + 1);
                    }}
                >
                    &gt;
                </span>
            </div>
        );
    }
}

export default connect(function mapStateToProps(state: IDefaultState, props) {
    return {
        currentPage: state.productData.currentPage,
        totalPages: state.productData.totalPages,
        curScreenBreakpoint: state.screenBreakpoints.curBreakpoint,
    };
})(PageNav);
