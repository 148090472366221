import { createSlice } from "@reduxjs/toolkit";
import { ICartItem } from "../../components/cart";
export interface IProductDataState {
    data: object[];
    filters: any[];
    columns: string[];
    uniqueId: string;
    sortFilter: string;
    searchText: string;
    componentList: any[];
    softwareList: any[];
    notebookList: any[];
    totalPages: number;
    pageSize: number;
    currentPage: number;
    filtersLoading: boolean;
    pagingLoading: boolean;
    dataLoading: boolean;
    cartItems: ICartItem[];
    customPcItems: { [component: string]: string[] };
}

export const productDataState: IProductDataState = {
    data: [],
    filters: [],
    columns: [],
    uniqueId: "",
    sortFilter: "",
    searchText: "",
    componentList: [],
    softwareList: [],
    notebookList: [],
    totalPages: 1,
    pageSize: 1,
    currentPage: 1,
    filtersLoading: false,
    pagingLoading: false,
    dataLoading: false,
    cartItems: [],
    customPcItems: {},
};

const productData = createSlice({
    name: "productData",
    initialState: productDataState,
    reducers: {
        setSearchText(state, action) {
            state.searchText = action.payload;
        },
        setComponentList(state, action) {
            state.componentList = action.payload;
        },
        setSoftwareList(state, action) {
            state.softwareList = action.payload;
        },
        setNotebookList(state, action) {
            state.notebookList = action.payload;
        },
        setProductData(state, action) {
            state.data = action.payload;
        },
        setTotalPages(state, action) {
            state.totalPages = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setProductFilters(state, action) {
            state.filters = action.payload;
        },
        setColumns(state, action) {
            state.columns = action.payload;
        },
        setSortFilter(state, action) {
            state.sortFilter = action.payload;
        },
        setFiltersLoading(state, action) {
            state.filtersLoading = action.payload;
        },
        setDataLoading(state, action) {
            state.dataLoading = action.payload;
        },
        setUniqueId(state, action) {
            state.uniqueId = action.payload;
        },
        setCartItems(state, action) {
            state.cartItems = action.payload;
        },
        setCustomPcItems(state, action) {
            state.customPcItems = action.payload;
        },
    },
});

export const { setSearchText, setComponentList, setSoftwareList, setNotebookList, setProductData, setTotalPages, setPageSize, setCurrentPage, setProductFilters, setColumns, setSortFilter, setFiltersLoading, setDataLoading, setUniqueId, setCartItems, setCustomPcItems } = productData.actions;

export default productData.reducer;
