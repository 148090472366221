export function getScreenWidth() {
    return window.innerWidth;
}

export function joinUrl(...urls: string[]): string {
    function parseUrl(url: string): string {
        if (url.startsWith("/")) {
            return parseUrl(url.slice(1));
        }
        if (url.endsWith("/")) {
            return parseUrl(url.slice(0, -1));
        }
        return url;
    }

    const parsedUrl = urls.map((url) => parseUrl(url)).join("/");

    return parsedUrl.startsWith("/") ? parsedUrl : `/${parsedUrl}`;
}
