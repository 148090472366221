import { ICartItem } from "../components/cart";
import { setCartItems, setColumns, setComponentList, setCurrentPage, setCustomPcItems, setDataLoading, setFiltersLoading, setNotebookList, setPageSize, setProductData, setProductFilters, setSearchText, setSoftwareList, setSortFilter, setTotalPages, setUniqueId } from "../redux/slices/productDataSlice";
import { store } from "../redux/store";

interface IProductFilter {
    name: string;
    values: string[];
    displayType: string;
    enabled: boolean[];
}

const tablePath = {
    path: {
        components: "/stock/components",
        software: "/stock/software",
        notebooks: "/stock/notebooks",
        saleCategories: "/stock/sale-categories",
        stock: "/stock",
        pages: "/stock/pages",
        filters: "/stock/filters",
        stockCount: "/stock/get-stock-count",
        order: "/order",
    },
};

export const categoryAliases: { [key: string]: string } = {
    "CPU/Processors": "Processors",
};

export const columnAliases: { [key: string]: string } = {
    ProductID: "Unique Identifier",
    Name: "Name",
    Categories: "Category",
    SellingPrice: "Price",
    CTStock: "In Stock",
    BackorderStock: "Backorder Stock",
    OnSale: "On Sale",
    CPUSpeed: "CPU Speed",
    DDRSpeed: "DDR Speed",
    MemoryType: "Memory Type",
    DIMMSlots: "DIMM Slots",
    SATASlots: "SATA Slots",
    "M.2Slots": "M.2 Slots",
    PCIex1Slots: "PCIe x1 Slots",
    PCIex16Slots: "PCIe x16 Slots",
    ResponseTime: "Response Time",
    RefreshRate: "Refresh Rate",
    ScreenSize: "Screen Size",
    CPUCores: "CPU Cores",
    MaxDPI: "Max DPI",
};

const DBName = "triceratech";

export class ProductService {
    private static lockedDataRequest = -1;
    private static lastDataRequest = -1;

    columnValuesToQueryString(columnValList: { [key: string]: string[] }, firstURLParam: boolean = true) {
        var formattedColumnValuesList: string = "";

        if (Object.keys(columnValList)) {
            for (const col in columnValList) {
                // eslint-disable-next-line no-loop-func
                columnValList[col].forEach((value: string) => (formattedColumnValuesList += "&" + col + "=" + encodeURIComponent(value)));
            }

            if (firstURLParam) formattedColumnValuesList = formattedColumnValuesList.replace(/&/, "?");
        }

        return formattedColumnValuesList;
    }

    filterListToQueryString(filterList: IProductFilter[] = [], columnList: string[] = [], firstQuery: boolean = false) {
        var formattedFilterList: string = "";

        if (filterList) {
            var filterCount = 0;
            filterList.forEach((filter: any, filterIndex: number) => {
                var addFilter: boolean = store.getState().productData.searchText.length > 0 ? filter.name === "Model" && !filter.enabled.includes(true) : false;
                filter.values.forEach((value: any, valueIndex: number) => {
                    if (filter.enabled[valueIndex] === true || addFilter) {
                        formattedFilterList += (firstQuery && filterCount === 0 ? "?" : "&") + filter.name.replace(" ", "_").replace(/[^\w]/, "0") + "=" + encodeURIComponent(value);

                        filterCount++;
                    }
                });
            });

            if (columnList) {
                columnList.forEach((column: string) => (formattedFilterList += "&" + column + "=" + encodeURIComponent("*")));
            }
        } else if (columnList) {
            columnList.forEach((column: string) => (formattedFilterList += "&" + column + "=" + encodeURIComponent("*")));
        }

        return formattedFilterList;
    }

    columnListToQueryString(columnList: string[]) {
        var formattedColumnList: string = "";

        if (columnList) {
            columnList.forEach((column: string) => (formattedColumnList += "&" + column + "=" + encodeURIComponent("*")));
        }

        return formattedColumnList;
    }

    setProductSearch(searchText: string = "") {
        store.dispatch(setSearchText(searchText));
    }

    async getImageLinks(uniqueId: string = "") {
        try {
            var imageLinks: string[] = await (await fetch(process.env.REACT_APP_DBPATH + "/product-images/" + (uniqueId.length > 1 ? uniqueId : store.getState().productData.uniqueId), { mode: "cors" })).json();
        } catch (error) {
            imageLinks = [];
        }

        return imageLinks.map((link: string) => {
            return process.env.REACT_APP_IMAGEPATH + link;
        });
    }

    async getBannerLinks() {
        try {
            var bannerLinks: string[] = await (
                await fetch(process.env.REACT_APP_DBPATH + "/banners", {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            bannerLinks = [];
        }

        return bannerLinks.map((link: string) => {
            return process.env.REACT_APP_IMAGEPATH + link;
        });
    }

    async getComponentList() {
        try {
            var components = await (
                await fetch(process.env.REACT_APP_DBPATH + tablePath.path.components, {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            components = [];
        }

        store.dispatch(
            setComponentList(
                components.Components.map((component: { name: string; subcategories: string[] }) => {
                    return categoryAliases[component.name]
                        ? {
                              name: categoryAliases[component.name],
                              subcategories: component.subcategories,
                          }
                        : component;
                })
            )
        );
    }

    async getSoftwareList() {
        try {
            var software = await (
                await fetch(process.env.REACT_APP_DBPATH + tablePath.path.software, {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            software = [];
        }

        store.dispatch(
            setSoftwareList(
                software.Software.map((software: { category: string; items: { uniqueId: string; name: string }[] }) => {
                    return software;
                })
            )
        );
    }

    async getNotebookList() {
        try {
            var notebooks = await (
                await fetch(process.env.REACT_APP_DBPATH + tablePath.path.notebooks, {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            notebooks = [];
        }

        store.dispatch(setNotebookList(notebooks.Notebooks));
    }

    async getCategories() {
        try {
            var categories = await (
                await fetch(process.env.REACT_APP_DBPATH + tablePath.path.saleCategories, {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            categories = [];
        }
        store.dispatch(setProductFilters([{ name: "Categories", values: categories }]));
    }

    setProductFiltersLoading(isLoading: boolean) {
        store.dispatch(setFiltersLoading(isLoading));
    }

    async getProductFilters(columnValueList: { [key: string]: string[] } = {}, searchText: string = "", selectedProductIds: string[] = [], dispatchToGlobalState: boolean = true) {
        if (dispatchToGlobalState) store.dispatch(setFiltersLoading(true));

        var filters: { [key: string]: any[] } = { Filters: [] };

        try {
            searchText = encodeURI(searchText).replace(";", ":");
            var columns: string = "";
            if (searchText.length > 0) {
                searchText = "?search=" + searchText;
                columns = this.columnValuesToQueryString(columnValueList, false);
            } else columns = this.columnValuesToQueryString(columnValueList, true);

            var selectedProducts: string = ((ids: string[], first: boolean) => {
                var formattedSelectedIds: string = "";
                ids.forEach((id: string, index: number) => {
                    if (index === 0 && first) formattedSelectedIds += `?selectedProducts=${encodeURIComponent(id)}`;
                    else formattedSelectedIds += `&selectedProducts=${encodeURIComponent(id)}`;
                });

                return formattedSelectedIds;
            })(selectedProductIds, columns.length < 1);

            console.log(process.env.REACT_APP_DBPATH + tablePath.path.filters + searchText + columns + selectedProducts);

            filters = await (
                await fetch(process.env.REACT_APP_DBPATH + tablePath.path.filters + searchText + columns + selectedProducts, {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            filters = { Filters: [] };
        }

        var filtersWithEnabledProp: any[] = [];

        filters.Filters.forEach((filter: any, index: number) => {
            filtersWithEnabledProp.push({
                name: filter.name,
                values: filter.displayType === "boolean" ? ["FALSE"] : filter.displayType === "range" && filter.values.length < 2 ? ["*"] : filter.values,
                displayType: filter.displayType,
                enabled: filter.values.map(() => {
                    if (filter.displayType === "range" || columnValueList[filter.name]) {
                        return true;
                    }

                    return false;
                }),
            });
        });

        if (dispatchToGlobalState) {
            store.dispatch(setProductFilters(filtersWithEnabledProp));
            store.dispatch(setFiltersLoading(false));
        }

        return filtersWithEnabledProp;
    }

    setProductFilters(filterList: IProductFilter[]) {
        store.dispatch(setProductFilters(filterList));
    }

    async getProductFilterCount(filterList: IProductFilter[] = store.getState().productData.filters) {
        var filterListString: string = this.filterListToQueryString(filterList, [], true);

        console.log(process.env.REACT_APP_DBPATH + tablePath.path.stockCount + filterListString);
        store.dispatch(setFiltersLoading(true));
        try {
            var stockCount = await (
                await fetch(process.env.REACT_APP_DBPATH + tablePath.path.stockCount + filterListString, {
                    mode: "cors",
                })
            ).json();
        } catch (error) {
            stockCount = {};
        }

        var newFilterList: any[] = [];

        filterList.forEach((filter: any) => {
            var nextItem: {
                [key: string]: string | string[] | number[] | boolean[];
            } = {
                name: filter.name,
                values: filter.values,
                displayType: filter.displayType,
                enabled: filter.enabled,
            };

            if (stockCount[filter.name]) nextItem["count"] = stockCount[filter.name];

            newFilterList.push(nextItem);
        });

        store.dispatch(setProductFilters(newFilterList));
        store.dispatch(setFiltersLoading(false));
    }

    async getTotalPages(pageSize: number = store.getState().productData.pageSize, filterList: IProductFilter[] = store.getState().productData.filters, columnValueList: { [key: string]: string[] } = {}) {
        var totalPages: any = 0;
        var filterListString: string = "";
        var columnValueListString: string = "";
        var columnListString: string = "";
        var sortFilter: string = store.getState().productData.sortFilter;

        if (filterList.length > 0) {
            filterListString = this.filterListToQueryString(filterList, store.getState().productData.columns, false);
        }
        if (columnValueList) {
            columnValueListString = this.columnValuesToQueryString(columnValueList, false);

            columnListString = this.columnListToQueryString(store.getState().productData.columns);
        }

        /*console.log(
      process.env.REACT_APP_DBPATH +
        tablePath.path.pages +
        "?pageSize=" +
        pageSize +
        filterListString +
        columnValueListString +
        columnListString +
        sortFilter
    );*/

        if (filterListString.length > 0 || columnValueListString.length > 0) {
            try {
                totalPages = await (
                    await fetch(process.env.REACT_APP_DBPATH + tablePath.path.pages + "?pageSize=" + pageSize + filterListString + columnValueListString + columnListString + sortFilter || "", {
                        mode: "cors",
                    })
                ).json();

                totalPages = totalPages.TotalPages;
            } catch (error) {
                totalPages = 0;
            }

            store.dispatch(setTotalPages(totalPages));
        }

        return totalPages;
    }

    setPageSize(pageSize: number = 10) {
        store.dispatch(setPageSize(pageSize));
    }

    setCurrentPage(curPage: number = 1) {
        store.dispatch(setCurrentPage(curPage));
    }

    setColumns(columnList: string[] = []) {
        store.dispatch(setColumns(columnList));
    }

    setSortFilter(sortFilter: string) {
        store.dispatch(setSortFilter(sortFilter));
    }

    setProductDataLoading(isLoading: boolean) {
        store.dispatch(setDataLoading(isLoading));
    }

    setProductUniqueId(id: string) {
        store.dispatch(setUniqueId(id));
    }

    async getProductData(columnValueList: { [key: string]: string[] } = {}, p_pageSize: number = -1, p_curPage: number = -1, randomSelection: boolean = false, getAllColumns: boolean = false, orSelect: string[] = [], dispatchToGlobalState: boolean = true): Promise<any[]> {
        var filterList: IProductFilter[] = store.getState().productData.filters;
        var columnList: string[] = store.getState().productData.columns;
        var sortFilter: string = store.getState().productData.sortFilter;
        var pageSize: number = p_pageSize > 0 ? p_pageSize : store.getState().productData.pageSize;
        var curPage: number = p_curPage > 0 ? p_curPage : store.getState().productData.currentPage;
        var uniqueId: string = store.getState().productData.uniqueId;
        var columnValueListString: string = "";
        var filterListString: string = "";
        var tableData: any = [{ NoResult: "" }];

        if (dispatchToGlobalState) store.dispatch(setDataLoading(true));

        if (uniqueId.length > 0) {
            try {
                tableData = await (
                    await fetch(process.env.REACT_APP_DBPATH + tablePath.path.stock + "?ProductID=" + encodeURIComponent(uniqueId) + "&allcols=1", {
                        mode: "cors",
                    })
                ).json();

                tableData = tableData.Items;
            } catch (error) {
                tableData = [];
            }

            if (dispatchToGlobalState) {
                store.dispatch(setProductData(tableData));
                store.dispatch(setDataLoading(false));
            }
        } else if ((filterList.length > 0 && columnList.length > 0) || Object.keys(columnValueList).length > 0) {
            filterListString = this.filterListToQueryString(Object.keys(columnValueList).length < 1 ? filterList : [], columnList, false);

            columnValueListString = this.columnValuesToQueryString(columnValueList, false);

            var curTime = new Date().getTime();

            if (ProductService.lockedDataRequest === -1) ProductService.lockedDataRequest = curTime;

            ProductService.lastDataRequest = curTime;

            /*console.log(
        process.env.REACT_APP_DBPATH +
          tablePath.path.stock +
          "?pageSize=" +
          pageSize +
          "&page=" +
          curPage +
          columnValueListString +
          filterListString +
          sortFilter +
          (randomSelection ? "&randomSelection=1" : "") +
          (getAllColumns ? "&allcols=1" : "")
      );*/

            try {
                tableData = await (
                    await fetch(process.env.REACT_APP_DBPATH + tablePath.path.stock + "?pageSize=" + pageSize + "&page=" + curPage + columnValueListString + filterListString + sortFilter + (randomSelection ? "&randomSelection=1" : "") + (getAllColumns ? "&allcols=1" : ""), {
                        mode: "cors",
                    })
                ).json();

                tableData = tableData.Items;
            } catch (error) {
                tableData = [];
            }

            if (curTime === ProductService.lockedDataRequest || curTime > ProductService.lockedDataRequest) {
                if (curTime === ProductService.lockedDataRequest) {
                    ProductService.lockedDataRequest = -1;
                    store.dispatch(setDataLoading(false));
                }

                if (tableData.length < 1) tableData = [{ NoResult: "" }];

                if (dispatchToGlobalState) store.dispatch(setProductData(tableData));
            }
        } else {
            if (dispatchToGlobalState) store.dispatch(setProductData(tableData));
            store.dispatch(setDataLoading(false));
        }
        return tableData;
    }

    initIndexDB(version: number = 1): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            var db: IDBDatabase | undefined;
            var request = indexedDB.open(DBName, version);

            request.onerror = (event: Event) => {
                console.log("Error connecting to IndexDB");
                reject(db);
            };

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                db = request.result;
                if (!db.objectStoreNames.contains("cart")) {
                    db.createObjectStore("cart", { keyPath: "timeStamp" });
                }
                resolve(db);
                console.log(`Database version was updated.`);
            };

            request.onsuccess = async (event: Event) => {
                db = request.result;

                resolve(db);
                console.log(`Database connection was successful for '${DBName}'`);
            };
        });
    }

    async getCartItemInfo() {}

    async getStoredCartItems() {
        try {
            var db: IDBDatabase | undefined = await this.initIndexDB();
        } catch (error: any) {
            db = error;
        }

        try {
            var cartItems: { UniqueId: string; quantity: number; timeStamp: number }[] | undefined = await new Promise((resolve, reject) => {
                if (db) {
                    var request: IDBRequest<any> = db.transaction("cart").objectStore("cart").getAll();
                    request.onsuccess = (event) => {
                        resolve(request.result);
                    };
                } else {
                    reject([]);
                }
            });
        } catch (error) {
            console.log("NO DB");
        }

        if (cartItems && cartItems.length > 0) {
            var uniqueIds: string[] = [];
            var quantities: number[] = [];
            var timeStamps: number[] = [];
            cartItems.forEach((item: { UniqueId: string; quantity: number; timeStamp: number }) => {
                uniqueIds.push(item.UniqueId);
                quantities.push(item.quantity);
                timeStamps.push(item.timeStamp);
            });
            try {
                var itemData = await (
                    await fetch(process.env.REACT_APP_DBPATH + tablePath.path.stock + `?pageSize=${uniqueIds.length}&page=1` + this.columnValuesToQueryString({ ProductID: uniqueIds }, false) + "&Name=*&SellingPrice=*&SaleAmount=*", {
                        mode: "cors",
                    })
                ).json();

                itemData = itemData.Items;
            } catch (error) {
                itemData = [];
            }

            if (itemData.length > 0) {
                this.addCartItems(
                    itemData.map((item: { [key: string]: string }) => {
                        let index: number = uniqueIds.indexOf(item["ProductID"]);
                        return {
                            UniqueId: item["ProductID"],
                            Name: item["Name"],
                            SellingPrice: +item["SellingPrice"].replace(/R|\s/g, ""),
                            SaleAmount: +item["SaleAmount"],
                            quantity: quantities[index],
                            timeStamp: timeStamps[index],
                        };
                    }),
                    db
                );
            }
        } else if (window.location.pathname === "/checkout") {
            window.location.href = "/home";
        }
    }

    async addCartItems(newItems: ICartItem[], db: IDBDatabase | undefined | null = null) {
        var currentItems: ICartItem[] = store.getState().productData.cartItems;
        var latestItemList: ICartItem[] = newItems;

        if (currentItems.length > 0) {
            var noRepeatNewItems: ICartItem[] = [];
            newItems.forEach((newItem: ICartItem) => {
                if (
                    currentItems.findIndex((item: ICartItem) => {
                        if (item.UniqueId === newItem.UniqueId) return true;
                        return false;
                    }) === -1
                )
                    noRepeatNewItems.push(newItem);
            });
            currentItems = currentItems.map((item: ICartItem) => {
                let newQuantity = 0;
                if (
                    newItems.findIndex((newItem: ICartItem) => {
                        if (item.UniqueId === newItem.UniqueId) {
                            newQuantity = newItem.quantity;
                            return true;
                        }

                        return false;
                    }) !== -1
                )
                    return { ...item, quantity: item.quantity + newQuantity };

                return item;
            });

            latestItemList = currentItems.concat(noRepeatNewItems);
        }

        if (!db) {
            try {
                db = await this.initIndexDB();
            } catch (error: any) {
                db = error;
            }
        }

        latestItemList.forEach((item: ICartItem, index: number) => {
            let transaction = db?.transaction("cart", "readwrite");
            if (transaction)
                transaction.objectStore("cart").put({
                    UniqueId: item.UniqueId,
                    quantity: item.quantity,
                    timeStamp: item.timeStamp,
                }).onsuccess = (event) => {
                    if (index === latestItemList.length - 1) db?.close();
                };
        });

        store.dispatch(setCartItems(latestItemList));
    }

    async removeCartItem(itemKey: number) {
        var newCartList = store.getState().productData.cartItems.filter((item: ICartItem) => {
            return !(item.timeStamp === itemKey);
        });
        store.dispatch(setCartItems(newCartList));

        try {
            var db: IDBDatabase | undefined = await this.initIndexDB();
        } catch (error) {
            db = error as undefined;
        }

        if (db) {
            let transaction: IDBTransaction = db.transaction("cart", "readwrite");
            if (transaction)
                transaction.objectStore("cart").delete(itemKey).onsuccess = (event) => {
                    "Item successfully removed from cart.";
                };
        }

        if (newCartList.length < 1 && window.location.pathname === "/checkout") window.location.href = "/home";
    }

    async setCartQuantity(itemKey: number, quantity: number) {
        let changedItem: ICartItem | undefined;
        quantity = quantity < 1 ? 1 : quantity;
        store.dispatch(
            setCartItems(
                store.getState().productData.cartItems.map((item: ICartItem) => {
                    if (item.timeStamp === itemKey) {
                        changedItem = { ...item, quantity: quantity };
                        return changedItem;
                    }
                    return item;
                })
            )
        );

        try {
            var db: IDBDatabase | undefined = await this.initIndexDB();
        } catch (error: any) {
            db = error;
        }

        if (db) {
            let transaction: IDBTransaction = db.transaction("cart", "readwrite");
            if (transaction)
                transaction.objectStore("cart").put(changedItem).onsuccess = (event) => {
                    "Item quantity successfully updated.";
                };
        }
    }

    async clearCart() {
        store.dispatch(setCartItems([]));

        try {
            var db: IDBDatabase | undefined = await this.initIndexDB();
        } catch (error: any) {
            db = error;
        }

        if (db) {
            let transaction: IDBTransaction = db.transaction("cart", "readwrite");
            if (transaction)
                transaction.objectStore("cart").clear().onsuccess = (event) => {
                    "Item cart successfully cleared.";
                };
        }

        if (window.location.pathname === "/checkout") window.location.href = "/home";
    }

    getStoredCustomPcItems(componentList: string[]) {
        var rawList: string | null = sessionStorage.getItem("component");
        var storedItems: { [component: string]: string[] } = {};
        componentList.forEach((component: string) => {
            let rawList: string | null = sessionStorage.getItem(component);
            storedItems[component] = rawList ? rawList.split(";") : [];
        });

        store.dispatch(setCustomPcItems(storedItems));
    }

    setSelectedCustomPcItem(component: string, index: number) {
        sessionStorage.setItem("selectedCustomPcItem", `${index}`);
    }

    clearSelectedCustomPcItem() {
        sessionStorage.removeItem("selectedCustomPcItem");
    }

    setCustomPcItem(component: string, uniqueId: string) {
        var rawList: string | null = sessionStorage.getItem(component);
        var updatedList: string[] = rawList ? rawList.split(";") : [];
        var selectedItem: string | null = sessionStorage.getItem("selectedCustomPcItem");

        if (selectedItem) updatedList[+selectedItem] = uniqueId;
        else if (updatedList.indexOf(uniqueId) === -1) updatedList.push(uniqueId);

        sessionStorage.removeItem(component);
        sessionStorage.setItem(component, updatedList.join(";"));

        var customPcItems: { [component: string]: string[] } = Object.assign({}, store.getState().productData.customPcItems);
        customPcItems[component] = updatedList;
        store.dispatch(setCustomPcItems(updatedList));
    }

    removeCustomPcItem(component: string, index: number) {
        var customPcItems: { [key: string]: string[] } = Object.assign({}, store.getState().productData.customPcItems);
        var valueFound = false;
        var componentIds: string[] = [];

        customPcItems[component].forEach((value: string, componentIndex: number) => {
            if (componentIndex !== index && value.length > 0) {
                componentIds.push(value);
                valueFound = true;
            }
        });

        if (valueFound) {
            customPcItems[component] = componentIds;
            sessionStorage.setItem(component, componentIds.join(";"));
        } else {
            sessionStorage.removeItem(component);
            let newList: { [component: string]: string[] } = {};
            for (const key in customPcItems) {
                if (key !== component) newList[key] = customPcItems[key];
            }
            customPcItems = newList;
        }

        store.dispatch(setCustomPcItems(customPcItems));
    }

    clearCustomPcItems() {
        var customPcItems: { [component: string]: string[] } = Object.assign({}, store.getState().productData.customPcItems);
        for (const component in customPcItems) sessionStorage.removeItem(component);
        store.dispatch(setCustomPcItems({}));
    }
}
