import * as React from "react";
import { NavDropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem";
import { connect } from "react-redux";
import { IDefaultState } from "../../redux/store";
import { ProductService } from "../../services/productService";
import { Cart } from "../cart";
import { IDropdownItem } from "../dropdownMenus/CustomDropdown";
import { UserService } from "../../services/userService";
import { Loader } from "../../components/loader";
import FeedbackModal from "../../components/modals/feedbackModal";
import { Link } from "react-router-dom";

export interface SiteNavProps {
    profileNavCollapsed?: boolean;
    searchCollapsed?: boolean;
    componentList: any[];
    softwareList: any[];
    notebookList: any[];
    username?: string;
}

class SiteNavState {
    searchText: string = "";
    componentList: IDropdownItem[] = [];
    notebookList: IDropdownItem[] = [];
    cartIsOpen: boolean = false;
    logOutError: string;
    logOutSuccess: boolean;
    loading: boolean;

    constructor(searchText: string = "", componentList: IDropdownItem[] = [], notebookList: IDropdownItem[] = [], cartIsOpen: boolean = false, logOutError: string = "", logOutSuccess: boolean = false, loading: boolean = false) {
        this.searchText = searchText;
        this.componentList = componentList;
        this.notebookList = notebookList;
        this.cartIsOpen = cartIsOpen;
        this.logOutError = logOutError;
        this.logOutSuccess = logOutSuccess;
        this.loading = loading;
    }
}

class SiteNav extends React.PureComponent<SiteNavProps, SiteNavState> {
    private productService = new ProductService();

    constructor(props: any) {
        super(props);
        this.state = new SiteNavState();
    }

    componentDidMount() {
        this.productService.getComponentList();
        this.productService.getSoftwareList();
        this.productService.getNotebookList();
        UserService.getUserInfo();
    }

    buildComponentList(list?: any[]) {
        var componentArr: JSX.Element[] = [];

        (list || this.props.componentList).forEach((value, index) => {
            if ((value.subcategories || []).length === 0) componentArr.push(<NavDropdown.Item onClick={value.callback ?? this.componentSelect.bind(this)}>{value.name}</NavDropdown.Item>);
            else {
                var subCategories: any[] = ["All"];

                value.subcategories.forEach((x: string) => subCategories.push(x));

                componentArr.push(
                    <NavDropdown title={value.name} id={`nav-components-${value.name}`} drop="right">
                        {this.buildComponentList(
                            subCategories.map((subcategory: string, subIndex: number) => {
                                return {
                                    name: subcategory,
                                    callback: () => this.componentSubcategorySelect(value.name, subcategory),
                                    itemIndex: subIndex - 1,
                                    sublist: null,
                                };
                            })
                        )}
                    </NavDropdown>
                );
            }
        });

        return componentArr;
    }

    buildNotebookList(list?: any[]) {
        var componentArr: JSX.Element[] = [];

        componentArr.push(<NavDropdown.Item onClick={() => (window.location.href = "/components/Notebooks")}>All</NavDropdown.Item>);

        (list || this.props.notebookList).forEach((value, index) => {
            componentArr.push(<NavDropdown.Item onClick={() => this.notebookSelect(value)}>{value.make}</NavDropdown.Item>);
        });

        return componentArr;
    }

    componentSelect(event: any) {
        var pageURL = "";

        this.props.componentList.findIndex((component: { name: string }) => {
            if (component.name === event.target.innerText) {
                pageURL = "/components/" + component.name;
                return true;
            }

            return false;
        });

        window.location.href = pageURL;
    }

    componentSubcategorySelect(category: string, subcategory: string) {
        var pageURL = `/components/${category}`;

        this.props.componentList.findIndex((component: { name: string; subcategories: string[] }) => {
            let subcategoryIndex: number = component.subcategories.findIndex((x: string) => {
                return x === subcategory;
            });

            if (subcategoryIndex !== -1) {
                pageURL += `/${component.subcategories[subcategoryIndex]}`;
                return true;
            }

            return false;
        });

        window.location.href = pageURL;
    }

    softwareSelect(event: any) {
        var pageURL = "";

        this.props.softwareList.findIndex((software: { category: string; items: { uniqueId: string; name: string }[] }) => {
            if (
                software.items.findIndex((item: { uniqueId: string; name: string }) => {
                    if (item.name === event.target.innerText) {
                        pageURL = "/products/" + item.uniqueId;
                        return true;
                    }
                    return false;
                }) !== -1
            )
                return true;

            return false;
        });

        window.location.href = pageURL;
    }

    notebookSelect(value: { make: string; models: string[] }) {
        var pageURL = `/Notebooks/${value.make}`;

        window.location.href = pageURL;
    }

    updateSearchText(event: any) {
        this.setState(new SiteNavState(event.target.value));
    }

    submitSearch(event: any) {
        event.preventDefault();

        window.location.href = window.location.pathname.substr(0, window.location.pathname.indexOf("/") + 1) + "Search?search=" + this.state.searchText;
    }

    async logOut() {
        this.setState({ ...this.state, loading: true });
        let response = await UserService.signOut();
        this.setState({
            ...this.state,
            logOutError: response.message,
            logOutSuccess: response.success,
            loading: false,
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading ? <Loader isModal /> : null}
                {this.state.logOutSuccess || this.state.logOutError.length > 0 ? <FeedbackModal closeButton centered className={"logout-modal"} headerText={this.state.logOutSuccess ? "Logged out successfully." : this.state.logOutError} onHide={() => window.location.reload()} /> : null}
                <Navbar className="site-nav navbar-default flex-grow-1" expand={this.props.profileNavCollapsed ? "xl" : "lg"}>
                    <Navbar.Toggle className="navbar-dark" aria-controls="navbar-collapse-main" />

                    <Navbar.Collapse id="navbar-collapse-main">
                        <Nav>
                            <NavItem>
                                <div className="d-flex flex-row">
                                    <Link id="home" to="/home" className="nav-link">
                                        Home
                                    </Link>
                                    <div className="nav-sep">|</div>
                                </div>
                            </NavItem>
                            <NavItem className="d-flex flex-row">
                                <NavDropdown title="Components" id="nav-components">
                                    {this.buildComponentList()}
                                </NavDropdown>
                                <div className="nav-sep">|</div>
                            </NavItem>
                            <NavItem className="d-flex flex-row">
                                <NavDropdown title="Notebooks" id="nav-Notebooks">
                                    {this.buildNotebookList()}
                                </NavDropdown>
                                <div className="nav-sep">|</div>
                            </NavItem>
                            <NavItem>
                                <div className="d-flex flex-row">
                                    <Link id="home" to="/buildacustompc" className="nav-link">
                                        Build a Custom PC
                                    </Link>
                                    <div className="nav-sep">|</div>
                                </div>
                            </NavItem>
                            <NavItem>
                                <div className="d-flex flex-row">
                                    <Link id="home" to="/sale" className="nav-link">
                                        Sale
                                    </Link>
                                    <div className="nav-sep">|</div>
                                </div>
                            </NavItem>
                            <NavItem>
                                <div className="d-flex flex-row">
                                    <Link id="contactus" to="/contactus" className="nav-link">
                                        Contact Us
                                    </Link>
                                    <div className="nav-sep">|</div>
                                </div>
                            </NavItem>
                            {!this.props.searchCollapsed ? null : (
                                <React.Fragment>
                                    <NavItem>
                                        <Form className="d-flex flex-row" onSubmit={this.submitSearch.bind(this)}>
                                            <Form.Control className="rounded-0 border-0" type="search" placeholder="Search" aria-label="Search" onChange={this.updateSearchText.bind(this)} />
                                            <Link to={this.state.searchText.length > 0 ? window.location.pathname.substr(0, window.location.pathname.indexOf("/") + 1) + "Search?search=" + this.state.searchText : "#"} className="nav-link">
                                                Search
                                            </Link>
                                        </Form>
                                    </NavItem>
                                </React.Fragment>
                            )}
                            {!this.props.profileNavCollapsed ? null : (
                                <Col className="profile-container p-0">
                                    {this.props.username ? (
                                        <React.Fragment>
                                            <div className="profile-name">{`Logged in as ${this.props.username}`}</div>
                                        </React.Fragment>
                                    ) : (
                                        <div className="navcollapse-sep" />
                                    )}
                                    <NavItem>
                                        <Link id="myCart" to="/cart" className="nav-link">
                                            My Cart
                                        </Link>
                                        <div className="nav-sep" />
                                    </NavItem>
                                    <NavItem>
                                        <Link id="faq" to="/faq" className="nav-link">
                                            FAQ
                                        </Link>
                                        <div className="nav-sep" />
                                    </NavItem>
                                    <NavItem>
                                        <Link id="termsandconditions" to="/termsandconditions" className="nav-link">
                                            {"Terms & Conditions"}
                                        </Link>
                                        <div className="nav-sep" />
                                    </NavItem>
                                    {!this.props.username ? (
                                        <React.Fragment>
                                            <NavItem>
                                                <Link id="login" to="/login" className="nav-link">
                                                    Login
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link id="register" to="/register" className="nav-link">
                                                    Register
                                                </Link>
                                            </NavItem>
                                        </React.Fragment>
                                    ) : (
                                        <NavItem>
                                            <Link id="login" to="#" onClick={(e: any) => this.logOut.bind(this)()} className="nav-link">
                                                Log Out
                                            </Link>
                                        </NavItem>
                                    )}
                                </Col>
                            )}
                        </Nav>
                    </Navbar.Collapse>

                    {!this.props.searchCollapsed ? (
                        <React.Fragment>
                            <Form className="form-inline ml-auto" onSubmit={this.submitSearch.bind(this)}>
                                <Form.Control className="rounded-0 border-0" type="search" placeholder="Search" aria-label="Search" onChange={this.updateSearchText.bind(this)} />
                                <Link to={this.state.searchText.length > 0 ? window.location.pathname.substr(0, window.location.pathname.indexOf("/") + 1) + "Search?search=" + this.state.searchText : "#"} className="nav-link">
                                    Search
                                </Link>
                                <Link
                                    className="cart px-3"
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            cartIsOpen: !this.state.cartIsOpen,
                                        });
                                    }}
                                    to="#"
                                >
                                    Cart
                                </Link>
                            </Form>
                        </React.Fragment>
                    ) : (
                        <NavItem>
                            <Nav.Link
                                className="cart"
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        cartIsOpen: !this.state.cartIsOpen,
                                    });
                                }}
                            >
                                Cart
                            </Nav.Link>
                        </NavItem>
                    )}
                    <Cart maxHeight={425} isOpen={this.state.cartIsOpen} />
                </Navbar>
            </React.Fragment>
        );
    }
}

export default connect(function mapStateToProps(state: IDefaultState, props) {
    return {
        componentList: state.productData.componentList,
        softwareList: state.productData.softwareList,
        notebookList: state.productData.notebookList,
        username: state.userData.currentUser ? state.userData.currentUser.username : "",
    };
})(SiteNav);
