import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface IFeedbackModalProps {
    className?: string;
    headerText?: string;
    bodyText?: string;
    closeButton?: boolean;
    closeButtonLocation?: "bottom" | "top";
    centered?: boolean;
    autoHide?: boolean;
    hideTimer?: number;
    hideTimerIncrement?: number;
    onHide?: () => void;
    onTimerIncrement?: (timerValue: number) => void;
}

class FeedbackModalState {
    countDown: number;
    showModal: boolean;

    constructor(countDown: number = 0, showModal: boolean = true) {
        this.countDown = countDown;
        this.showModal = showModal;
    }
}

export default class FeedbackModal extends React.PureComponent<IFeedbackModalProps, FeedbackModalState> {
    constructor(props: IFeedbackModalProps) {
        super(props);
        this.state = new FeedbackModalState(this.props.autoHide ? (this.props.hideTimer ? this.props.hideTimer : 5000) : 0);
    }

    componentDidMount() {
        if (this.props.autoHide) this.countDownCloseModal();
    }

    async countDownCloseModal() {
        if (this.props.onTimerIncrement) this.props.onTimerIncrement(this.state.countDown);
        if (this.state.countDown > 0)
            setInterval(() => {
                let timerValue = this.state.countDown;
                if (this.state.countDown > 0) {
                    timerValue = timerValue - (this.props.hideTimerIncrement ? this.props.hideTimerIncrement : 1000);
                    this.setState({
                        ...this.state,
                        countDown: timerValue,
                    });
                } else {
                    timerValue = 0;
                    this.closeModal();
                }

                if (this.props.onTimerIncrement) this.props.onTimerIncrement(timerValue);
            }, 1000);
    }

    closeModal() {
        clearInterval();
        this.setState({ ...this.state, showModal: false });
        if (this.props.onHide) this.props.onHide();
    }

    render() {
        return (
            <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)} centered={this.props.centered} dialogClassName={this.props.className ? this.props.className : "feedback-modal"}>
                <Modal.Header closeButton={this.props.closeButton && this.props.closeButtonLocation !== "bottom"}>
                    <Modal.Title>{this.props.headerText}</Modal.Title>
                </Modal.Header>
                {this.props.bodyText ? <Modal.Body>{this.props.bodyText}</Modal.Body> : null}
                {this.props.closeButton && this.props.closeButtonLocation === "bottom" ? (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal.bind(this)}>
                            Close
                        </Button>
                    </Modal.Footer>
                ) : null}
            </Modal>
        );
    }
}
