import * as React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { IDefaultState } from "../../redux/store";
import { columnAliases, ProductService } from "../../services/productService";
import { CustomDropdown, IDropdownItem } from "../dropdownMenus/CustomDropdown";
import PageNav from "./pageNav";

const sortBy: { [key: string]: string } = {
    Name: "Name",
    SellingPrice: "Price",
};
const itemsPerPage = ["10", "25", "50", "100"];

interface TableNavOptions {
    sortFilter: boolean;
}
interface ITableNavProps {
    tableColumns: any[];
    productFilters: any[];
    currentPage: number;
    options?: TableNavOptions;
}

class TableNavState {
    pageSize: number = 10;
    filterNames: IDropdownItem[] = [];
    sortFilter: string = "";
    ascending: boolean = true;

    constructor(pageSize: number = 10, filterNames: IDropdownItem[] = [], sortFilter: string = "", ascending: boolean = true) {
        this.pageSize = pageSize;
        this.filterNames = filterNames;
        this.sortFilter = sortFilter;
        this.ascending = ascending;
    }
}

class TableNav extends React.PureComponent<ITableNavProps, TableNavState> {
    private itemsPerPage: IDropdownItem[] = [];
    private productService: ProductService = new ProductService();

    constructor(props: any) {
        super(props);

        this.state = new TableNavState();

        itemsPerPage.forEach((value, index) => {
            this.itemsPerPage.push({
                label: value,
                callback: this.setItemsPerPage.bind(this),
                itemIndex: index,
                sublist: null,
            });
        });
    }

    buildFilterNames() {
        var filterNames: any[] = [];

        if (this.props.tableColumns) {
            this.props.tableColumns.forEach((value, index) => {
                if (sortBy[value]) {
                    filterNames.push({
                        label: sortBy[value],
                        callback: this.sortFilterSelected.bind(this),
                        itemIndex: index,
                    });
                }
            });
        }

        return filterNames;
    }

    sortFilterSelected(event: any) {
        var sortColumn = event.target.innerText;

        for (const property in sortBy) if (sortBy[property] === sortColumn) sortColumn = property;

        if (this.state.ascending) {
            this.productService.setSortFilter("&sort=ASC&sortby=" + encodeURI(sortColumn));
            this.productService.getProductData();
        } else {
            this.productService.setSortFilter("&sort=DSC&sortby=" + encodeURI(sortColumn));
            this.productService.getProductData();
        }

        try {
            sessionStorage.setItem("sortColumn", sortColumn);
        } catch (error) {}

        this.setState(new TableNavState(this.state.pageSize, this.state.filterNames, sortColumn, this.state.ascending));
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.tableColumns !== this.props.tableColumns) {
            let localStorageSortBy: string | null = null;
            let localStorageSortDirection: string | null = null;
            let localStoragePageSize: string | null = null;

            try {
                localStorageSortBy = sessionStorage.getItem("sortColumn");
                localStorageSortDirection = sessionStorage.getItem("sortDirection");
                localStoragePageSize = sessionStorage.getItem("tablePageSize");
            } catch (error) {}

            this.productService.setSortFilter("&sort=" + (localStorageSortDirection ? localStorageSortDirection : "ASC") + "&sortby=" + (localStorageSortBy ? localStorageSortBy : this.props.tableColumns[1]));

            this.productService.setPageSize(localStoragePageSize ? +localStoragePageSize : this.state.pageSize);

            this.setState(new TableNavState(localStoragePageSize ? +localStoragePageSize : this.state.pageSize, this.state.filterNames, localStorageSortBy ? localStorageSortBy : this.state.sortFilter, localStorageSortDirection ? (localStorageSortDirection === "ASC" ? true : false) : this.state.ascending));
        }
    }

    async setItemsPerPage(event: any) {
        var pageSize = parseInt(event.target.innerText);
        this.setState({ ...this.state, pageSize: pageSize });
        this.productService.setPageSize(pageSize);
        this.productService.getTotalPages(pageSize);
        this.productService.getProductData();

        try {
            sessionStorage.setItem("tablePageSize", "" + pageSize);
        } catch (error) {}
    }

    sort(event: React.MouseEvent) {
        if (!this.state.ascending) {
            this.productService.setSortFilter("&sort=ASC&sortby=" + encodeURIComponent(this.state.sortFilter));
            this.setState({ ...this.state, ascending: true });
            try {
                sessionStorage.setItem("sortDirection", "ASC");
            } catch (error) {}
        } else {
            this.productService.setSortFilter("&sort=DSC&sortby=" + encodeURIComponent(this.state.sortFilter));
            this.setState({ ...this.state, ascending: false });
            try {
                sessionStorage.setItem("sortDirection", "DSC");
            } catch (error) {}
        }

        this.productService.getProductData();
    }

    render() {
        return (
            <Container className="page-nav" fluid>
                <Row>
                    {(this.props.options && this.props.options.sortFilter) || !this.props.options ? (
                        <Col className="sort-columns p-0" sm="auto">
                            <Row className="nav-item d-flex flex-row mr-auto">
                                <Col className="sort-col d-flex flex-row" xs="auto">
                                    <span className="label">Sort By</span>
                                    <CustomDropdown className="dropdown" itemList={this.buildFilterNames()} setHeaderToSelected={true} defaultSelected={sortBy[this.state.sortFilter]} itemPixelHeight={26.4} />
                                    <div className="sort-updown d-flex flex-column w-0">
                                        <div className={"caret-up" + (this.state.ascending ? " active" : "")} onClick={this.sort.bind(this)}></div>
                                        <div className={"caret-down" + (!this.state.ascending ? " active" : "")} onClick={this.sort.bind(this)}></div>
                                    </div>
                                </Col>
                                <Col className="sizeperpage-col d-flex flex-row p-0" xs="auto">
                                    <span className="label">Items/Page</span>
                                    <CustomDropdown className="dropdown" itemList={this.itemsPerPage} setHeaderToSelected={true} defaultSelected={"" + this.state.pageSize} itemPixelHeight={26.4} />
                                </Col>
                            </Row>
                        </Col>
                    ) : null}

                    <Col className="page-selector-col d-flex flex-column p-0 ml-auto" sm="auto">
                        <PageNav className="page-selector" pageSize={this.state.pageSize} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default connect(function mapStateToProps(state: IDefaultState, props) {
    return {
        tableColumns: state.productData.columns,
        productFilters: state.productData.filters,
        currentPage: state.productData.currentPage,
    };
})(TableNav);
