import { createSlice } from "@reduxjs/toolkit";

export interface IUserDataState {
    currentUser: any;
}

export const userDataState: IUserDataState = {
    currentUser: {},
};

const userData = createSlice({
    name: "userData",
    initialState: userDataState,
    reducers: {
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        },
    },
});

export const { setCurrentUser } = userData.actions;

export default userData.reducer;
