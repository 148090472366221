import * as React from "react";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { IDefaultState } from "../../redux/store";
import { UserService } from "../../services/userService";
import FeedbackModal from "../../components/modals/feedbackModal";
import { Loader } from "../../components/loader";
import { Link } from "react-router-dom";

interface IProfileNavProps {
    username?: string;
}

class ProfileNavState {
    loading: boolean;
    logOutError: string;
    logOutSuccess: boolean;

    constructor(loading: boolean = false, logOutError: string = "", logOutSuccess: boolean = false) {
        this.loading = loading;
        this.logOutError = logOutError;
        this.logOutSuccess = logOutSuccess;
    }
}

class ProfileNav extends React.PureComponent<IProfileNavProps, ProfileNavState> {
    constructor(props: IProfileNavProps) {
        super(props);
        this.state = new ProfileNavState();
    }
    componentDidMount() {
        UserService.getUserInfo();
    }

    async logOut() {
        this.setState({ ...this.state, loading: true });
        let response = await UserService.signOut();
        this.setState({
            ...this.state,
            logOutError: response.message,
            logOutSuccess: response.success,
            loading: false,
        });
    }

    render() {
        return (
            <Col xs="auto" className="profile-container">
                {this.state.loading ? <Loader isModal /> : null}
                {this.state.logOutSuccess || this.state.logOutError.length > 0 ? <FeedbackModal closeButton closeButtonLocation="bottom" centered className={"logout-modal"} headerText={this.state.logOutSuccess ? "Logged out successfully." : this.state.logOutError} onHide={() => window.location.reload()} /> : null}
                {this.props.username && this.props.username.length > 0 ? <div className="profile-name">{`Logged in as ${this.props.username}`}</div> : null}
                <div className="profile-nav">
                    <div className="profile-link d-flex flex-row">
                        <Link to="/cart" className="nav-link p-1">
                            My Cart
                        </Link>
                        <div className="nav-sep d-flex align-items-center">|</div>
                    </div>
                    <div className="profile-link d-flex flex-row">
                        <Link to="/faq" className="nav-link p-1">
                            FAQ
                        </Link>
                        <div className="nav-sep d-flex align-items-center">|</div>
                    </div>
                    <div className="profile-link d-flex flex-row">
                        <Link to="/termsandconditions" className="nav-link p-1">
                            Terms &amp; Conditions
                        </Link>
                        <div className="nav-sep d-flex align-items-center">|</div>
                    </div>
                    <div className="profile-link d-flex flex-row">
                        {this.props.username ? (
                            <Link to="#" onClick={(e) => this.logOut.bind(this)()} className="nav-link p-1">
                                Log Out
                            </Link>
                        ) : (
                            <Link to="/login" className="nav-link p-1">
                                Login
                            </Link>
                        )}
                        <div className="nav-sep d-flex align-items-center">|</div>
                    </div>
                    {!this.props.username ? (
                        <div className="profile-link d-flex flex-row">
                            <Link to="/register" className="nav-link p-1">
                                Register
                            </Link>
                        </div>
                    ) : null}
                </div>
            </Col>
        );
    }
}

export default connect(function mapStateToProps(state: IDefaultState, props: IProfileNavProps) {
    return {
        username: state.userData.currentUser ? state.userData.currentUser.username : "",
    };
})(ProfileNav);
