import React, { Suspense } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "./App.css";
import { ProfileNav, SiteNav } from "./components/navigation";
import { getScreenWidth, joinUrl } from "./helpers/utils";
import { breakPoints, setScreenBreakpoint } from "./redux/slices/screenBreakpointsSlice";
import { IDefaultState } from "./redux/store";
import routes, { Route as LocalRoute } from "./Routes";
import { ProductService } from "./services/productService";
import ScrollTop from "./components/navigation/scrollTop";

class AppState {}

class AppProps {
    curScreenBreakpoint: number = -1;
    dispatch: any;
}

class App extends React.PureComponent<AppProps, AppState> {
    private productService: ProductService = new ProductService();

    constructor(props: AppProps) {
        super(props);

        this.state = new AppState();
    }

    componentWillMount() {
        this.props.dispatch(setScreenBreakpoint(getScreenWidth()));
    }

    componentDidMount() {
        window.addEventListener("resize", () => this.forceUpdate());
        this.productService.getStoredCartItems();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => this.forceUpdate());
    }

    forceUpdate() {
        this.props.dispatch(setScreenBreakpoint(getScreenWidth()));
    }

    renderRoute(route: LocalRoute, parentPath = "") {
        const component = React.createElement(route.Component, {}, route.routes && route.routes.map((r) => this.renderRoute(r, route.path)));

        return (
            <Route key={route.path} path={joinUrl(parentPath, route.path)} exact={route.path === "/"}>
                {component}
            </Route>
        );
    }

    toggleCart() {}

    render() {
        var profileCollapsed: boolean = this.props.curScreenBreakpoint < breakPoints.lg ? true : false;

        var searchCollapsed: boolean = this.props.curScreenBreakpoint < breakPoints.xs ? true : false;

        var showSingleLogo: boolean = this.props.curScreenBreakpoint < breakPoints.sm ? true : false;

        return (
            <Router>
                <Container fluid className="header">
                    <Row>
                        {!showSingleLogo ? (
                            <Col className="col-2 col-lg-3 col-xl-4">
                                <div>
                                    <Link to="/home" className="nav-link">
                                        <img alt="" className="img-header-1" src="/img/logo.png" />
                                    </Link>
                                </div>
                            </Col>
                        ) : null}
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <Link to="/home" className="nav-link">
                                    <img alt="" className="img-header-2" src="/img/banner.png" />
                                </Link>
                            </div>
                        </Col>
                        {profileCollapsed ? null : (
                            <Col>
                                <ProfileNav />
                            </Col>
                        )}
                    </Row>
                </Container>
                <SiteNav profileNavCollapsed={profileCollapsed} searchCollapsed={searchCollapsed} />
                <ScrollTop />
                <Container fluid className="body d-flex">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>{routes.map((route) => this.renderRoute(route))}</Switch>
                    </Suspense>
                </Container>
                <footer className="footer" style={{ color: "black" }}>
                    <Row>
                        <Col xs="auto" sm="3" md="4" lg="5" className="p-0"></Col>
                        <Col xs="auto" className="d-flex flex-column align-items-end">
                            <div className="contact-column d-flex flex-column">
                                <div className="social-links d-inline-flex">
                                    <span className="connect">Connect with us at:</span>
                                    <a className="nav-link socialmediaicon" href="https://www.facebook.com/TriceraTechPCs/" target="_blank" rel="noopener noreferrer">
                                        <img src="/img/facebook.svg" alt="Tricerteach | Facebook" />
                                    </a>
                                </div>
                                <span className="email">
                                    Email:&nbsp;
                                    <a href="mailto:jason@triceratech.co.za">jason@triceratech.co.za</a>
                                </span>
                            </div>
                        </Col>
                        <Col className="d-flex flex-column align-items-end justify-content-center">
                            <small>
                                Powered by:{" "}
                                <a href="https://www.dev200.co.za" target="_blank" rel="noreferrer">
                                    <img src="/img/dev200-logo.png" alt="Dev200" />
                                </a>
                            </small>
                        </Col>
                    </Row>
                </footer>
            </Router>
        );
    }
}

export default connect(function mapStateToProps(state: IDefaultState, props) {
    return {
        curScreenBreakpoint: state.screenBreakpoints.curBreakpoint,
    };
})(App);
