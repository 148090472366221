import { combineReducers } from "@reduxjs/toolkit";
import ProductDataReducer from "./slices/productDataSlice";
import ScreenBreakpoints from "./slices/screenBreakpointsSlice";
import UserDataReducer from "./slices/userDataSlice";

export default combineReducers({
    screenBreakpoints: ScreenBreakpoints,
    productData: ProductDataReducer,
    userData: UserDataReducer,
});
