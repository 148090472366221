import { setCurrentUser } from "../redux/slices/userDataSlice";
import { store } from "../redux/store";
import { Auth, Amplify } from "aws-amplify";
import awsConfig from "../aws-exports";

Amplify.configure(awsConfig);

export interface ICognitoFeedback {
    success: boolean;
    message: string;
}

export interface IUserAttributes {
    email: string;
    name: string;
    family_name: string;
    phone_number: string;
    address: string;
    "custom:city": string;
    "custom:postalcode": string;
    "custom:province": string;
}

export class UserService {
    static async signIn(username: string, password: string): Promise<ICognitoFeedback> {
        try {
            const user = await Auth.signIn(username, password);
            setCurrentUser(user.attributes);
            return { success: true, message: "" };
        } catch (error: any) {
            return { success: false, message: error.message };
        }
    }

    static async signUp(signupInfo: {
        username: string;
        password: string;
        attributes: {
            email: string;
            name: string;
            family_name: string;
            phone_number: string;
            address: string;
            "custom:city": string;
            "custom:postalcode": string;
            "custom:province": string;
        };
    }): Promise<ICognitoFeedback> {
        try {
            await Auth.signUp(signupInfo);
            return { success: true, message: "" };
        } catch (error: any) {
            return { success: false, message: error.message };
        }
    }

    static async signOut(): Promise<ICognitoFeedback> {
        try {
            await Auth.signOut();
            return { success: true, message: "" };
        } catch (error: any) {
            return { success: false, message: error.message };
        }
    }

    static async getUserInfo() {
        try {
            let user = await Auth.currentUserInfo();
            store.dispatch(setCurrentUser(user));
        } catch (error: any) {
            console.log(error.message);
        }
    }

    static async requestPasswordReset(username: string): Promise<ICognitoFeedback> {
        if (username.length > 0) {
            try {
                await Auth.forgotPassword(username);
            } catch (error: any) {
                console.log(error.message);
            }

            return { success: true, message: "" };
        }

        return { success: false, message: "Username cannot be empty" };
    }

    static async submitNewPassword(username: string, verificationCode: string, newPassword: string): Promise<ICognitoFeedback> {
        if (username.length > 0 && verificationCode.length > 0 && newPassword.length > 0) {
            try {
                await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
                return { success: true, message: "" };
            } catch (error: any) {
                return { success: false, message: error.message };
            }
        }

        return {
            success: false,
            message: "Username/Verification code/new password is empty",
        };
    }
}
