import React from "react";
import ReactDOM from "react-dom";

interface ILoaderProps {
    isModal?: boolean;
}
export default class Loader extends React.PureComponent<ILoaderProps> {
    render() {
        let loadbar: JSX.Element = <img className="data-loadbar" data-modal={this.props.isModal} alt="" src="/img/loader.png" />;

        if (this.props.isModal) {
            return ReactDOM.createPortal(
                <div
                    className="data-loadbar-container"
                    style={{
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: `${window.getComputedStyle(document.body).height}`,
                        width: "100%",
                        zIndex: 100,
                    }}
                >
                    {loadbar}
                </div>,
                document.body
            );
        }

        return loadbar;
    }
}
