import * as React from "react";
import ReactDOM from "react-dom";

class ScrollTopState {
    thresholdReached: boolean;

    constructor(thresholdReached: boolean = false) {
        this.thresholdReached = thresholdReached;
    }
}

export default class ScrollTop extends React.PureComponent<{}, ScrollTopState> {
    constructor(props: any) {
        super(props);
        this.state = new ScrollTopState();
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll.bind(this));
    }

    handleScroll(event: any) {
        if (window.scrollY > 0.4 * document.body.scrollHeight) this.setState({ thresholdReached: true });
        else this.setState({ thresholdReached: false });
    }

    render() {
        return ReactDOM.createPortal(
            <div
                className="scroll-top"
                data-active={this.state.thresholdReached ? "1" : "0"}
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
            >
                ^
            </div>,
            document.body
        );
    }
}
